import {  Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ArrayHelper {

  // crea una lista con elementos agrupados, recibe una lista y un arreglo con los campos
  distinct(lst, fields) {
    const lstResult = [];

    lst.forEach(element => {
      const obj = {};

      fields.forEach(e => {
        obj[e] = element[e];
      });

      if (!lstResult.map(e => e[fields[0]]).some(e => e === obj[fields[0]])) {
        lstResult.push(obj);
      }


    });
    return lstResult;
  }
}
